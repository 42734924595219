import {REGISTRO_INVENTARIO_GET_LIST} from "../actions";
import {REGISTRO_INVENTARIO_GET_LIST_SUCCESS} from "../actions";
import {REGISTRO_INVENTARIO_GET_LIST_ERROR} from "../actions";
import {REGISTRO_INVENTARIO_ADD} from "../actions";
import {REGISTRO_INVENTARIO_ADD_SUCCESS} from "../actions";
import {REGISTRO_INVENTARIO_ADD_ERROR} from "../actions";
import {REGISTRO_INVENTARIO_EDIT} from "../actions";
import {REGISTRO_INVENTARIO_EDIT_SUCCESS} from "../actions";
import {REGISTRO_INVENTARIO_EDIT_ERROR} from "../actions";

export const getRegistroInventario = () => ({
    type: REGISTRO_INVENTARIO_GET_LIST
});
export const getRegistroInventarioSuccess = (items) => ({
    type: REGISTRO_INVENTARIO_GET_LIST_SUCCESS,
    payload: items
});
export const getRegistroInventarioError = (error) => ({
    type: REGISTRO_INVENTARIO_GET_LIST_ERROR,
    payload: error
});

export const addRegistroInventario = (item) => ({
    type: REGISTRO_INVENTARIO_ADD,
    payload: item
});
export const addRegistroInventarioSuccess = (items) => ({
    type: REGISTRO_INVENTARIO_ADD_SUCCESS,
    payload: items
});
export const addRegistroInventarioError = (error) => ({
    type: REGISTRO_INVENTARIO_ADD_ERROR,
    payload: error
});

export const editRegistroInventario = (item) => ({
    type: REGISTRO_INVENTARIO_EDIT,
    payload: item
});
export const editRegistroInventarioSuccess = (items) => ({
    type: REGISTRO_INVENTARIO_EDIT_SUCCESS,
    payload: items
});
export const editRegistroInventarioError = (error) => ({
    type: REGISTRO_INVENTARIO_EDIT_ERROR,
    payload: error
});


