import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  ADMIT_MEMBERS,
  MEMBERS_TO_ADMIT_GET
} from "../actions";

import {getMembersToAdmitSuccess} from "./actions";
import {getMembersToAdmitError} from "./actions";
import * as firebase from "firebase";
import {admitMembersSuccess} from "./actions";
import {admitMembersError} from "./actions";
import moment from "moment";

const db = firebase.firestore().collection('soci');

const getMembersToAdmitRequest = async () => {
  return await new Promise((success, fail) => {
    const members = [];
    let total = 0;
    let toAdmit = 0;
    db.where('deleted_at', '==', null).get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        total++;
        let member = doc.data();
        if(typeof member.dataAccettazione === "undefined" || member.dataAccettazione === null || member.dataAccettazione === "") {
          toAdmit++;
          members.push({
            ...member,
            idSocio: doc.id,
          });
        }
      });
      let admitted = total - toAdmit;
      success({
        members,
        info: {
          total,
          toAdmit,
          admitted
        }
      });


    });
  })
    .then(response => response)
    .catch(error => error);
};
function* getMembersToAdmitItems() {
  try {
    const response = yield call(getMembersToAdmitRequest);
    yield put(getMembersToAdmitSuccess(response));
  } catch (error) {
    yield put(getMembersToAdmitError(error));
  }
}
export function* watchGetMembersToAdmit() {
  yield takeEvery(MEMBERS_TO_ADMIT_GET, getMembersToAdmitItems);
}

const admitMembersRequest = async members => {
  return await new Promise((success, fail) => {
    members.map(member => {
      const updateRef = firebase.firestore().collection('soci').doc(member.idSocio);
      let _member = member;
      delete _member.idSocio;
      _member.dataAccettazione = moment().format();
      return updateRef.set(_member).then((docRef) => {
        return success();
      }).catch(error => error);
    })
  })
    .then(response => response)
    .catch(error => error);
};
function* admitMembersRequestItems({payload}) {
  try {
    const admittedMember = yield call(admitMembersRequest, payload);
    const updatedMembers = yield call(getMembersToAdmitRequest);
    yield put(admitMembersSuccess(admittedMember));
    yield put(getMembersToAdmitSuccess(updatedMembers));
  } catch (error) {
    yield put(admitMembersError(error));
  }
}
export function* watchAdmitMembers() {
  yield takeEvery(ADMIT_MEMBERS, admitMembersRequestItems);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetMembersToAdmit),
    fork(watchAdmitMembers)
  ]);
}
