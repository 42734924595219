import {
  MEMBERS_TO_ADMIT_GET,
  MEMBERS_TO_ADMIT_GET_SUCCESS,
  MEMBERS_TO_ADMIT_GET_ERROR,
  MEMBERS_TO_ADMIN_SELECT,
  ADMIT_MEMBERS,
  ADMIT_MEMBERS_SUCCESS,
  ADMIT_MEMBERS_ERROR
} from '../actions';


export const getMembersToAdmit = () => ({
  type: MEMBERS_TO_ADMIT_GET
});

export const getMembersToAdmitSuccess = (members) => ({
  type: MEMBERS_TO_ADMIT_GET_SUCCESS,
  payload: members
});

export const getMembersToAdmitError = (error) => ({
  type: MEMBERS_TO_ADMIT_GET_ERROR,
  payload: error
});

export const setMembersToAdmit = (items) => ({
  type: MEMBERS_TO_ADMIN_SELECT,
  payload: items
})

export const admitMembers = (items) => ({
  type: ADMIT_MEMBERS,
  payload: items
})

export const admitMembersSuccess = () => ({
  type: ADMIT_MEMBERS_SUCCESS
})

export const admitMembersError = (error) => ({
  type: ADMIT_MEMBERS_ERROR,
  payload: error
})


