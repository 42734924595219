import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import memberSagas from './members/saga';
import memberDetailSaga from './memberDetail/saga';
import memberAdmissionSaga from './memberAdmission/saga';
import registroInventario from './registroInventario/saga';


export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    memberSagas(),
    memberDetailSaga(),
    memberAdmissionSaga(),
    registroInventario()
  ]);
}
