import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  getRegistroInventarioSuccess,
  getRegistroInventarioError,
  addRegistroInventarioSuccess,
  addRegistroInventarioError,
  editRegistroInventarioError,
} from "./actions";
import {REGISTRO_INVENTARIO_ADD, REGISTRO_INVENTARIO_EDIT, REGISTRO_INVENTARIO_GET_LIST} from "../actions";
import * as firebase from "firebase";

const db = firebase.firestore();

const getRegistroInventarioRequest = async () => {
  return await new Promise((success, fail) => {
    const ref = db.collection('registroInventario');
    ref.onSnapshot(querySnapshot => {

      const registroInventario = [];
      querySnapshot.forEach(doc => {
        registroInventario.push({
          ...doc.data(),
          idBene: doc.id,
        });
      });
      success(registroInventario);
    });
  })
    .then(response => response)
    .catch(error => error);
};
function* getRegistroInventarioItems() {
  try {
    const response = yield call(getRegistroInventarioRequest);
    yield put(getRegistroInventarioSuccess(response));
  } catch (error) {
    yield put(getRegistroInventarioError(error));
  }
}
export function* watchGetList() {
  yield takeEvery(REGISTRO_INVENTARIO_GET_LIST, getRegistroInventarioItems);
}

const addRegistroInventarioRequest = async (item) => {
  return await new Promise((success, fail) => {
    const riRef = db.collection('registroInventario').doc();
    const batch = db.batch();
    batch.set(riRef, item);
    batch.commit();
    success(item);
  })
    .then(response => response)
    .catch(error => error);
};
function* addRegistroInventarioItems({ payload }) {
  try {
    const response = yield call(addRegistroInventarioRequest, payload);
    yield put(addRegistroInventarioSuccess(response));
  } catch (error) {
    yield put(addRegistroInventarioError(error));
  }
}
export function* watchAddItem() {
  yield takeEvery(REGISTRO_INVENTARIO_ADD, addRegistroInventarioItems);
}

const editRegistroInventarioRequest = async (item) => {
  return await new Promise((success, fail) => {
    const riRef = db.collection('registroInventario').doc(item.idBene);
    const batch = db.batch();
    batch.set(riRef, item);
    batch.commit();
    success(item);
  })
    .then(response => response)
    .catch(error => error);
};
function* editRegistroInventarioItems({ payload }) {
  try {
    yield call(editRegistroInventarioRequest, payload);
    const items = yield call(getRegistroInventarioRequest);
    yield put(getRegistroInventarioSuccess(items));
  } catch (error) {
    yield put(editRegistroInventarioError(error));
  }
}
export function* watchEditItem() {
  yield takeEvery(REGISTRO_INVENTARIO_EDIT, editRegistroInventarioItems);
}

export default function* rootSaga() {
  yield all([fork(watchGetList), fork(watchAddItem), fork(watchEditItem)]);
}
