import {
    MEMBER_GET_LIST,
    MEMBER_GET_LIST_SUCCESS,
    MEMBER_GET_LIST_ERROR,
} from '../actions';

export const getMemberList = () => ({
    type: MEMBER_GET_LIST
});

export const getMemberListSuccess = (items) => ({
    type: MEMBER_GET_LIST_SUCCESS,
    payload: items
});

export const getMemberListError = (error) => ({
    type: MEMBER_GET_LIST_ERROR,
    payload: error
});
