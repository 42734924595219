import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  getMemberListSuccess,
  getMemberListError,
} from "./actions";
import {MEMBER_GET_LIST} from "../actions";
import * as firebase from "firebase";

const db = firebase.firestore().collection('soci');

const getMemberListRequest = async () => {
  return await new Promise((success, fail) => {
    db.where('deleted_at', '==', null).orderBy('numeroSocio', 'asc').onSnapshot(querySnapshot => {
      const members = [];
      querySnapshot.forEach(doc => {
        members.push({
          ...doc.data(),
          idSocio: doc.id,
        });
      });
      success(members);
    });
  })
    .then(response => response)
    .catch(error => error);
};

function* getMemberListItems() {
  try {
    const response = yield call(getMemberListRequest);
    yield put(getMemberListSuccess(response));
  } catch (error) {
    yield put(getMemberListError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(MEMBER_GET_LIST, getMemberListItems);
}


export default function* rootSaga() {
  yield all([fork(watchGetList)]);
}
