import {
	MEMBER_GET_LIST,
	MEMBER_GET_LIST_SUCCESS,
	MEMBER_GET_LIST_ERROR
} from '../actions';


const INIT_STATE = {
	members: [],
	selectedMember: null,
	loading: false,
	error: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case MEMBER_GET_LIST:
			return { ...state, loading: false };
		case MEMBER_GET_LIST_SUCCESS:
			return { ...state, loading: true, members: action.payload };
		case MEMBER_GET_LIST_ERROR:
			return { ...state, loading: true, error: action.payload };
		default: return { ...state };
	}
}
