
/*
Per permette al sistema di considerare anche i valori decimali, il prezzo viene memorizzato per intero, moltiplicato per 100.
Nella fase di stampa quindi deve essere diviso per 100
 */
export function printPrice(price) {
  return price/100;
}
export function memorizePrice(price) {
  return parseInt(price*100);
}
