import moment from "moment";
import {memorizePrice} from "../helpers/Prices";
import {defaultValues} from "../constants/defaultValues";

export class Socio {
  constructor() {
    this.socioVuoto = {
      idSocio: '',
      numeroSocio: '',
      titolo: 'Sig.',
      nome: '',
      cognome: '',
      dataDiNascita: null,
      provinciaDiNascita: '',
      comuneDiNascita: '',
      indirizzoDiResidenza: '',
      cap: '',
      comune: '',
      provincia: '',
      codiceFiscale: '',
      dataRichiestaIscrizione: null,
      dataAccettazione: null,
      qualifica: "Socio",
      email: '',
      phone: '',
      fax: '',
      quoteAssociative: [{
        quota: memorizePrice(defaultValues.quotaAssociativaAnnua),
        dataPagamento: moment().format(),
        dataScadenza: moment().add(1, 'year').format()
      }],
      deleted_at: null
    }
  }

  nuovoSocio() {
    return {
      ...this.socioVuoto,
      dataRichiestaIscrizione: moment().format(),
      dataAccettazione: moment().format()
    }
  }

}
