import {
	MEMBERS_TO_ADMIT_GET,
	MEMBERS_TO_ADMIT_GET_SUCCESS,
	MEMBERS_TO_ADMIT_GET_ERROR,
	MEMBERS_TO_ADMIN_SELECT,
	ADMIT_MEMBERS,
	ADMIT_MEMBERS_ERROR,
	ADMIT_MEMBERS_SUCCESS
} from '../actions';


const INIT_STATE = {
	loading: false,
	error: null,
	membersToAdmit: [],
	selectedMembers: [],
	info: {
		total: null,
		toAdmit: null,
		admitted: null
	}
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case MEMBERS_TO_ADMIT_GET:
			return { ...state, loading: false, error: null };
		case MEMBERS_TO_ADMIT_GET_SUCCESS:
			return { ...state, loading: true, membersToAdmit: action.payload.members, info: action.payload.info, error: null };
		case MEMBERS_TO_ADMIT_GET_ERROR:
			return { ...state, loading: true, error: action.payload };
		case MEMBERS_TO_ADMIN_SELECT:
			return { ...state, selectedMembers: action.payload };
		case ADMIT_MEMBERS:
			return { ...state, loading: false, error: null };
		case ADMIT_MEMBERS_SUCCESS:
			return { ...state, loading: true, member: action.payload, error: false };
		case ADMIT_MEMBERS_ERROR:
			return { ...state, loading: true, error: action.payload };
		default: return { ...state };
	}
}
