import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  getMemberDetailSuccess,
  getMemberDetailError,
  addMemberDetailSuccess,
  editMemberDetailSuccess,
  editMemberDetailError,
  addMemberDetailError,
  deleteMemberDetailSuccess, deleteMemberDetailError,
} from "./actions";
import {MEMBER_ADD_DETAIL, MEMBER_DELETE_DETAIL, MEMBER_EDIT_DETAIL, MEMBER_GET_DETAIL} from "../actions";

import * as firebase from "firebase";
import moment from "moment";
import {getMemberList} from "../members/actions";

const db = firebase.firestore();

const getMemberDetailRequest = async idMember => {
  return await new Promise((success, fail) => {
    db.collection('soci').doc(idMember).get().then((doc) => {
      if (doc.exists) {
        success({
          ...doc.data(),
          idSocio: idMember
        });
      }
    });
  })
    .then(response => response)
    .catch(error => error);
};
function* getMemberDetailItems({payload}) {
  try {
    const response = yield call(getMemberDetailRequest, payload);
    yield put(getMemberDetailSuccess(response));
  } catch (error) {
    yield put(getMemberDetailError(error));
  }
}
export function* watchGetMemberDetail() {
  yield takeEvery(MEMBER_GET_DETAIL, getMemberDetailItems);
}

const addMemberDetailRequest = async member => {
  return await new Promise((success, fail) => {
    const sociRef = db.collection('soci').doc();
    const statsRef = db.collection('infoSoci').doc('--stats--');
    const increment = firebase.firestore.FieldValue.increment(1);
    const batch = db.batch();
    let lastMemberNumber;
    statsRef.get().then(function(doc) {
      if (doc.exists) {
        lastMemberNumber = doc.data().lastMemberNumber;
        batch.update(statsRef, { lastMemberNumber: increment });
      } else {
        lastMemberNumber = 0;
        batch.set(statsRef, { lastMemberNumber: increment });
      }
      member.numeroSocio = lastMemberNumber + 1;
      member.idSocio = sociRef.id;
      batch.set(sociRef, member);
      batch.commit();
      success(member);
    }).catch(error => error);
  })
    .then(response => response)
    .catch(error => error);
};
function* addMemberDetailItems({ payload }) {
  try {
    const response = yield call(addMemberDetailRequest, payload);
    yield put(addMemberDetailSuccess(response));
  } catch (error) {
    yield put(addMemberDetailError(error));
  }
}
export function* watchAddMemberDetail() {
  yield takeEvery(MEMBER_ADD_DETAIL, addMemberDetailItems);
}


const editMemberDetailRequest = async member => {
  return await new Promise((success, fail) => {
    const updateRef = firebase.firestore().collection('soci').doc(member.idSocio);
    let _member = {...member};
    delete _member.idSocio;
    updateRef.set(_member).then((docRef) => {
      success(member);
    }).catch(error => {console.log("errore updateRef: ", error)});
  }).then(response => response)
    .catch(error => error);
};
function* editMemberDetailItems({ payload }) {
  try {
    const response = yield call(editMemberDetailRequest, payload);
    yield put(editMemberDetailSuccess(response));
  } catch (error) {
    yield put(editMemberDetailError(error));
  }
}
export function* watchEditMemberDetail() {
  yield takeEvery(MEMBER_EDIT_DETAIL, editMemberDetailItems);
}

const deleteMemberDetailRequest = async member => {
  return await new Promise((success, fail) => {
    const updateRef = firebase.firestore().collection('soci').doc(member.idSocio);
    let _member = {...member};
    delete _member.idSocio;
    _member.deleted_at = moment().format();
    updateRef.set(_member).then((docRef) => {
      success(member);
    }).catch(error => {console.log("errore updateRef: ", error)});
  }).then(response => response)
    .catch(error => error);
};
function* deleteMemberDetailItems({ payload }) {
  try {
    const memberDeleted = yield call(deleteMemberDetailRequest, payload);
    yield put(deleteMemberDetailSuccess(memberDeleted));
    yield put(getMemberList());
  } catch (error) {
    yield put(deleteMemberDetailError(error));
  }
}
export function* watchDeleteMemberDetail() {
  yield takeEvery(MEMBER_DELETE_DETAIL, deleteMemberDetailItems);
}



export default function* rootSaga() {
  yield all([
    fork(watchGetMemberDetail),
    fork(watchAddMemberDetail),
    fork(watchEditMemberDetail),
    fork(watchDeleteMemberDetail)
  ]);
}
