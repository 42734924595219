import {
	REGISTRO_INVENTARIO_ADD,
	REGISTRO_INVENTARIO_ADD_ERROR,
	REGISTRO_INVENTARIO_ADD_SUCCESS,
	REGISTRO_INVENTARIO_EDIT,
	REGISTRO_INVENTARIO_EDIT_ERROR,
	REGISTRO_INVENTARIO_EDIT_SUCCESS,
	REGISTRO_INVENTARIO_GET_LIST,
	REGISTRO_INVENTARIO_GET_LIST_ERROR,
	REGISTRO_INVENTARIO_GET_LIST_SUCCESS
} from '../actions';
import {NotificationManager} from "../../components/common/react-notifications";

const INIT_STATE = {
	registroInventario: [],
	loading: true
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case REGISTRO_INVENTARIO_GET_LIST:
			return { ...state, loading: false };
		case REGISTRO_INVENTARIO_GET_LIST_SUCCESS:
			return { ...state, loading: true, registroInventario: action.payload };
		case REGISTRO_INVENTARIO_GET_LIST_ERROR:
			NotificationManager.error(
				action.payload,
				"Si è verificato un errore!",
				3000,
				null,
				null,
				""
			);
			return { ...state, loading: true, error: action.payload };
		case REGISTRO_INVENTARIO_ADD:
			return { ...state, loading: false };
		case REGISTRO_INVENTARIO_ADD_SUCCESS:
			console.log(state.registroInventario, action.payload);
			return { ...state, loading: true, registroInventario: [action.payload, ...state.registroInventario] };
		case REGISTRO_INVENTARIO_ADD_ERROR:
			NotificationManager.error(
				action.payload,
				"Si è verificato un errore!",
				3000,
				null,
				null,
				""
			);
			return { ...state, loading: true, error: action.payload };
		case REGISTRO_INVENTARIO_EDIT:
			return { ...state, loading: false };
		case REGISTRO_INVENTARIO_EDIT_SUCCESS:
			console.log(state.registroInventario, action.payload);
			state = {
				...state,
				registroInventario: state.registroInventario
			}
			return { ...state, loading: true, registroInventario: [] };
		case REGISTRO_INVENTARIO_EDIT_ERROR:
			NotificationManager.error(
				action.payload,
				"Si è verificato un errore!",
				3000,
				null,
				null,
				""
			);
			return { ...state, loading: true, error: action.payload };
		default: return { ...state };
	}
}
