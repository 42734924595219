import {
  MEMBER_GET_DETAIL,
  MEMBER_GET_DETAIL_SUCCESS,
  MEMBER_GET_DETAIL_ERROR,
  MEMBER_EDIT_DETAIL,
  MEMBER_EDIT_DETAIL_SUCCESS,
  MEMBER_EDIT_DETAIL_ERROR,
  MEMBER_ADD_DETAIL,
  MEMBER_ADD_DETAIL_SUCCESS,
  MEMBER_ADD_DETAIL_ERROR,
  MEMBER_DELETE_DETAIL,
  MEMBER_DELETE_DETAIL_SUCCESS,
  MEMBER_DELETE_DETAIL_ERROR,
  MEMBER_DETAIL_RESET_ERROR,
} from '../actions';
import {Socio} from "../../models/socio";


const INIT_STATE = {
	member: new Socio().nuovoSocio(),
  memberToAdd: new Socio().nuovoSocio(),
  lastMemberAdded: null,
	loading: false,
	error: null,
	memberDeletedSuccesfull: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case MEMBER_GET_DETAIL:
			return { ...state, loading: false, member: null };
		case MEMBER_GET_DETAIL_SUCCESS:
			return { ...state, loading: true, member: action.payload };
		case MEMBER_GET_DETAIL_ERROR:
			return { ...state, loading: true, error: action.payload };

		case MEMBER_EDIT_DETAIL:
			return { ...state, loading: false, error: null };
		case MEMBER_EDIT_DETAIL_SUCCESS:
			return { ...state, loading: true, member: action.payload, error: false };
		case MEMBER_EDIT_DETAIL_ERROR:
			return { ...state, loading: true, error: action.payload };

		case MEMBER_ADD_DETAIL:
			return { ...state, loading: false, memberToAdd: action.payload, error: null };
		case MEMBER_ADD_DETAIL_SUCCESS:
			return { ...state, loading: true, memberToAdd: new Socio().nuovoSocio(), lastMemberAdded: action.payload, error: false };
		case MEMBER_ADD_DETAIL_ERROR:
			return { ...state, loading: true, error: action.payload };

		case MEMBER_DELETE_DETAIL:
			return { ...state, loading: false, error: null };
		case MEMBER_DELETE_DETAIL_SUCCESS:
			return { ...state, loading: true, member: action.payload, error: false };
		case MEMBER_DELETE_DETAIL_ERROR:
			return { ...state, loading: true, error: action.payload };

    case MEMBER_DETAIL_RESET_ERROR:
      return { ...state, error: null}

		default: return { ...state };
	}
}
