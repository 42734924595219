/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

export const MEMBER_GET_LIST = "MEMBER_GET_LIST";
export const MEMBER_GET_LIST_SUCCESS = "MEMBER_GET_LIST_SUCCESS";
export const MEMBER_GET_LIST_ERROR = "MEMBER_GET_LIST_ERROR";

export const MEMBER_DETAIL_RESET_ERROR = "MEMBER_DETAIL_RESET_ERROR";
export const MEMBER_GET_DETAIL = "MEMBER_GET_DETAIL";
export const MEMBER_GET_DETAIL_SUCCESS = "MEMBER_GET_DETAIL_SUCCESS";
export const MEMBER_GET_DETAIL_ERROR = "MEMBER_GET_DETAIL_ERROR";
export const MEMBER_ADD_DETAIL = "MEMBER_ADD_DETAIL";
export const MEMBER_ADD_DETAIL_SUCCESS = "MEMBER_ADD_DETAIL_SUCCESS";
export const MEMBER_ADD_DETAIL_ERROR = "MEMBER_ADD_DETAIL_ERROR";
export const MEMBER_EDIT_DETAIL = "MEMBER_EDIT_DETAIL";
export const MEMBER_EDIT_DETAIL_SUCCESS = "MEMBER_EDIT_DETAIL_SUCCESS";
export const MEMBER_EDIT_DETAIL_ERROR = "MEMBER_EDIT_DETAIL_ERROR";
export const MEMBER_DELETE_DETAIL = "MEMBER_DELETE_DETAIL";
export const MEMBER_DELETE_DETAIL_SUCCESS = "MEMBER_DELETE_DETAIL_SUCCESS";
export const MEMBER_DELETE_DETAIL_ERROR = "MEMBER_DELETE_DETAIL_ERROR";

export const MEMBERS_TO_ADMIT_GET = "MEMBERS_TO_ADMIT_GET";
export const MEMBERS_TO_ADMIT_GET_SUCCESS = "MEMBERS_TO_ADMIT_GET_SUCCESS";
export const MEMBERS_TO_ADMIT_GET_ERROR = "MEMBERS_TO_ADMIT_GET_ERROR";
export const MEMBERS_TO_ADMIN_SELECT = "MEMBERS_TO_ADMIN_SELECT";
export const ADMIT_MEMBERS = "ADMIT_MEMBERS";
export const ADMIT_MEMBERS_SUCCESS = "ADMIT_MEMBERS_SUCCESS";
export const ADMIT_MEMBERS_ERROR = "ADMIT_MEMBERS_ERROR";

export const REGISTRO_INVENTARIO_GET_LIST = "REGISTRO_INVENTARIO_LIST_GET";
export const REGISTRO_INVENTARIO_GET_LIST_SUCCESS = "REGISTRO_INVENTARIO_GET_LIST_SUCCESS";
export const REGISTRO_INVENTARIO_GET_LIST_ERROR = "REGISTRO_INVENTARIO_GET_LIST_ERROR";
export const REGISTRO_INVENTARIO_ADD = "REGISTRO_INVENTARIO_GET";
export const REGISTRO_INVENTARIO_ADD_SUCCESS = "REGISTRO_INVENTARIO_GET_SUCCESS";
export const REGISTRO_INVENTARIO_ADD_ERROR = "REGISTRO_INVENTARIO_GET_ERROR";
export const REGISTRO_INVENTARIO_EDIT = "REGISTRO_INVENTARIO_EDIT";
export const REGISTRO_INVENTARIO_EDIT_SUCCESS = "REGISTRO_INVENTARIO_EDIT_SUCCESS";
export const REGISTRO_INVENTARIO_EDIT_ERROR = "REGISTRO_INVENTARIO_EDIT_ERROR";

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./members/actions";
export * from "./memberDetail/actions";
export * from "./memberAdmission/actions";
