const dev = {
  firebaseConfig: {
    "apiKey": "AIzaSyDaU7GHeRrNA2OzyZiZ8Wx91oWnDDC2YXM",
    "authDomain": "amministrazione-pc-dev.firebaseapp.com",
    "databaseURL": "https://amministrazione-pc-dev.firebaseio.com",
    "projectId": "amministrazione-pc-dev",
    "storageBucket": "amministrazione-pc-dev.appspot.com",
    "messagingSenderId": "279439245413",
    "appId": "1:279439245413:web:bc31a3be9898c45cdec68c",
    "measurementId": "G-6058ZNJZ7Z"
  }
};

const prod = {
  firebaseConfig: {
    "apiKey": "AIzaSyCUxMyVgoBDdgIy0mAr-OT4jqkRnsJKuSw",
    "authDomain": "amministrazione-pc.firebaseapp.com",
    "databaseURL": "https://amministrazione-pc.firebaseio.com",
    "projectId": "amministrazione-pc",
    "storageBucket": "amministrazione-pc.appspot.com",
    "messagingSenderId": "577589446851",
    "appId": "1:577589446851:web:2ba6de07892ffd014dfa76"
  }
};

const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  ...config
};
