import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import memberManager from './members/reducer';
import memberDetail from './memberDetail/reducer';
import memberAdmission from './memberAdmission/reducer';
import registroInventario from './registroInventario/reducer';


const reducers = combineReducers({
  memberManager,
  memberDetail,
  memberAdmission,
  menu,
  settings,
  authUser,
  registroInventario
});

export default reducers;
