import {
  MEMBER_GET_DETAIL,
  MEMBER_GET_DETAIL_SUCCESS,
  MEMBER_GET_DETAIL_ERROR,

  MEMBER_EDIT_DETAIL,
  MEMBER_EDIT_DETAIL_SUCCESS,
  MEMBER_EDIT_DETAIL_ERROR,

  MEMBER_ADD_DETAIL,
  MEMBER_ADD_DETAIL_SUCCESS,
  MEMBER_ADD_DETAIL_ERROR,
  MEMBER_DELETE_DETAIL,
  MEMBER_DELETE_DETAIL_SUCCESS,
  MEMBER_DELETE_DETAIL_ERROR,
  MEMBER_DETAIL_RESET_ERROR,
} from '../actions';


export const editMemberDetail = (member) => ({
  type: MEMBER_EDIT_DETAIL,
  payload: member
});

export const editMemberDetailSuccess = (member) => ({
  type: MEMBER_EDIT_DETAIL_SUCCESS,
  payload: member
});

export const editMemberDetailError = (error) => ({
  type: MEMBER_EDIT_DETAIL_ERROR,
  payload: error
});

export const addMemberDetail = (member) => ({
  type: MEMBER_ADD_DETAIL,
  payload: member
});

export const addMemberDetailSuccess = (member) => ({
  type: MEMBER_ADD_DETAIL_SUCCESS,
  payload: member
});

export const addMemberDetailError = (error) => ({
  type: MEMBER_ADD_DETAIL_ERROR,
  payload: error
});

export const getMemberDetail = (idMember) => ({
  type: MEMBER_GET_DETAIL,
  payload: idMember
});

export const getMemberDetailSuccess = (items) => ({
  type: MEMBER_GET_DETAIL_SUCCESS,
  payload: items
});

export const getMemberDetailError = (error) => ({
  type: MEMBER_GET_DETAIL_ERROR,
  payload: error
});

export const deleteMemberDetail = (member) => ({
  type: MEMBER_DELETE_DETAIL,
  payload: member
});
export const deleteMemberDetailSuccess = (member) => ({
  type: MEMBER_DELETE_DETAIL_SUCCESS,
  payload: member
});
export const deleteMemberDetailError = (error) => ({
  type: MEMBER_DELETE_DETAIL_ERROR,
  payload: error
});

export const resetError = () => ({
  type: MEMBER_DETAIL_RESET_ERROR
});
